import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { fetchwebdevelopmentservice, base_api } from './Axios/Axios';
import {Helmet} from "react-helmet";


function Webdevelopement() {

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, []);

    
  return (
<div className="serviceaboutsection">
  <Helmet>
    <title>Web Development Service | Phoenix Marketing</title>
    <meta name="description" content="The top professional and creative web design and development company in Madurai. We provide a wide range of online services to build SEO-friendly websites." />
    <meta name="keywords" content="Website development company in Madurai, Madurai website designer, Madurai leading website development company, Website services company in Madurai, Website builder Madurai, Web design services in Madurai, Top web design company in Madurai, Website creator in Madurai, Website creating company in Madurai" />
    <link rel="canonical" href="https://phoenixmarketing.in/web-development-service/" />
  </Helmet>
    <div className="aboutcontent">
      <div  className="aboutsection-image-all-service">
        <img src="/Imagefile/Web Development.png" className="aboutsection-image" />
      </div>
      <div  className="service-text-center">
        <h1>Web Development</h1>
        <div className="service-underline"></div>
        <p>Web application development is being employed widely in today's businesses across the globe. We create B2B and B2C compatible
          web applications to meet the business challenges. Our developers will create web application that the way you need them, from single 
          page application to customized complex web applications. Own a web application and transform your business. We offer 360 degree
          support in developing your web application.
        </p>
      </div>
    </div>
</div>
  );
} 


export default Webdevelopement


