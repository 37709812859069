// import React, { useEffect, useRef, useState } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
// import Aos from "aos";
// import 'aos/dist/aos.css';
// import { fetchfourthsection, base_api } from './Axios/Axios';


// function Portfolio() {
//   const [forthsection, setForthsection] = useState([]);
//   const [isBackendError, setIsBackendError] = useState(false);

//   useEffect(() => {
//     const fetchdata = async () => {
//       try {
//         const forthsectiondata = await fetchfourthsection();
//         setForthsection(forthsectiondata)
//       } catch (error) {
//         setIsBackendError(true);
//       }
//     };

//     fetchdata();
//   }, []);

//   useEffect(() => {
//     Aos.init({ duration: 2000 })
//   }, []);


//   const renderportfoliosection = () => {
//     if (isBackendError || !forthsection.length) {
//   return (
//     <div className="portfolio-header">
//       <div className="portfolio-bg">
//         <div className="portfolio-content">
//           <p>Our works</p>
//           {/* <img src="/Imagefile/4thsec_bulb.png" className="ourservice-seo" /> */}
//           <h3>Here's a sneak peek of what <br/> we are passionate about!</h3>
//         </div>

// <div className="carousel-container1">
//   <div className="carousel-content">
//     <Carousel className='mb-5'>
//       <Carousel.Item style={{ height:'400px', cursor:'pointer'}}>
//         <img src="Imagefile/Paper-Mockup 2.jpg" className="d-block bulb three-img" alt="Slide 1" />
//       </Carousel.Item>
//       <Carousel.Item style={{ height:'400px', cursor:'pointer'}}>
//         <img src="Imagefile/Newspaper mockup 2.jpg" className="d-block laptop three-img" alt="Slide 2" />
//       </Carousel.Item>
//       <Carousel.Item style={{ height:'400px', cursor:'pointer'}}>
//         <img src="Imagefile/Shree Shopping Bag Mockup copy.jpg" className="d-block slat three-img" alt="Slide 3" />
//       </Carousel.Item>
//     </Carousel>
//   </div>
// </div>

//         <img src="Imagefile/4thsec_slat.png" className="portfolio-slat"/>

//         <button type="submit" className="startbtn-protfolio" href="#">
//           Explore more <i className="fa-solid fa-plus"></i>
//         </button>
//       </div>
//     </div>
//   );
// } else {
//   return forthsection.map((item) => (
//     <React.Fragment key={item.id}>
//     <div className="portfolio-header">
//       <div className="portfolio-bg">
//         <div className="portfolio-content">
//           <p dangerouslySetInnerHTML={{ __html: item.title }}/>
//           {/* <img src="/Imagefile/4thsec_bulb.png" className="ourservice-seo" /> */}
//           <h3 dangerouslySetInnerHTML={{ __html: item.subtitle }}/>
//         </div>

// <div className="carousel-container1">
//   <div className="carousel-content">
//     <Carousel className='mb-5'>
//       <Carousel.Item style={{ height:'400px', cursor:'pointer'}}>
//         <img src={`${base_api}${item.Lapimage}`} className="d-block bulb three-img" alt="Slide 1" />
//       </Carousel.Item>
//       <Carousel.Item style={{ height:'400px', cursor:'pointer'}}>
//         <img src={`${base_api}${item.Lapimage}`} className="d-block laptop three-img" alt="Slide 2" />
//       </Carousel.Item>
//       <Carousel.Item style={{ height:'400px', cursor:'pointer'}}>
//         <img src={`${base_api}${item.Lapimage}`} className="d-block slat three-img" alt="Slide 3" />
//       </Carousel.Item>
//     </Carousel>
//   </div>
// </div>

//         <img src={`${base_api}${item.Lapimage}`} className="portfolio-slat"/>

//         {/* <button type="submit" className="startbtn-protfolio" href="#">
//           Explore more <i className="fa-solid fa-plus"></i>
//         </button> */}
//         <button className="startbtn-protfolio" href={forthsection.button_link}> {forthsection.button_text}
//                 <i className="fa-solid fa-plus"></i>
//               </button>
//       </div>
//     </div>

//     </React.Fragment>
//   ));
// }
// };

// return (
//   <div>
//     {renderportfoliosection()}
//   </div>
// );
// }


// export default Portfolio;




import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Portfolio.css'
import Aos from "aos";
import 'aos/dist/aos.css';
import { fetchfourthsection, base_api } from './Axios/Axios';

function Portfolio() {
  const [fourthsection, setFourthsection] = useState([]);
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fourthsectionData = await fetchfourthsection();
        // console.log("Fetched data:", fourthsectionData); // Log fetched data
        setFourthsection(fourthsectionData);
        setIsBackendError(false);
      } catch (error) {
        // console.error("Error fetching portfolio data:", error);
        setIsBackendError(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const renderPortfolioSection = () => {
    if (isBackendError || !fourthsection.length) {
      return (
        <div className="portfolio-header">
          <div className="portfolio-bg">
            <div className="portfolio-content">
              <p>Our works</p>
              <h3>Here's a sneak peek of <br />
              <p className='passionate'>What we are passionate about!</p></h3>
            </div>
            <div className="carousel-container1">
              <div className="carousel-content">
                <Carousel className='mb-5'>
                  <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                    <img src="/Social Media Poster/photo_8_2024-04-15_14-05-04.jpg" className="d-block bulb three-img" alt="Slide 1" />
                  </Carousel.Item>
                  <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                    <img src="/Social Media Poster/photo_2_2024-04-15_14-05-04.jpg" className="d-block laptop three-img" alt="Slide 2" />
                  </Carousel.Item>
                  <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                    <img src="/Logo Design/photo_17_2024-04-15_14-07-44.jpg" className="d-block slat three-img" alt="Slide 3" />
                  </Carousel.Item>
                  <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                    <img src="/Logo Design/photo_20_2024-04-15_14-07-44.jpg" className="d-block slat three-img" alt="Slide 3" />
                  </Carousel.Item>
                  <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                    <img src="/Logo Design/photo_23_2024-04-15_14-07-44.jpg" className="d-block slat three-img" alt="Slide 3" />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
            {/* <img src="Imagefile/4thsec_slat.png" className="portfolio-slat" alt="Decorative" /> */}
          <div className='explore-more'>
            <button type="button" className="startbtn-portfolio">
            <a href="/socialmediamarketing">Explore More</a>
             <i className="fa-solid fa-plus"></i>
            </button>
          </div>
          </div>
        </div>
      );
    } else {
      return fourthsection.map((item) => (
        <React.Fragment key={item.id}>
          <div className="portfolio-header">
            <div className="portfolio-bg">
              <div className="portfolio-content">
                <p dangerouslySetInnerHTML={{ __html: item.title }} />
                <h3 dangerouslySetInnerHTML={{ __html: item.subtitle }} />
              </div>
              <div className="carousel-container1">
                <div className="carousel-content">
                  <Carousel className='mb-0'>
                    <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                      <img src={`${base_api}${item.lapimage}`} className="d-block bulb three-img" alt="Slide 1" />
                    </Carousel.Item>
                    <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                      <img src={`${base_api}${item.lapimage}`} className="d-block laptop three-img" alt="Slide 2" />
                    </Carousel.Item>
                    <Carousel.Item style={{ height: '400px', cursor: 'pointer' }}>
                      <img src={`${base_api}${item.lapimage}`} className="d-block slat three-img" alt="Slide 3" />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
              {/* <img src={`${base_api}${item.png2}`} className="portfolio-slat" alt="Decorative" /> */}
              <button className="startbtn-protfolio" href="/socialmediamarketing"> {item.button_text}
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
        </React.Fragment>
      ));
    }
  };

  return (
    <div>
      {renderPortfolioSection()}
    </div>
  );
}

export default Portfolio;


