import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar";
import { Link } from 'react-router-dom';
import './Socailmediamarketing.css';
import Socialmediaposter from './Socialmediaposter';
import Packagedesign from './Packagedesign';
import Logowork from './Logowork';
import Videomaking from './Videomaking';
import Designuiux from './Designuiux';
import Aos from "aos";
import 'aos/dist/aos.css';
import Footer from "./Footer";
import {Helmet} from "react-helmet";


function Socialmediamarketing() {
    // Initialize state
    const [activeOption, setActiveOption] = useState("Video Making"); // Default active option
    const [showContent, setShowContent] = useState(false);
    const [showContent1, setShowContent1] = useState(false);
    const [showContainerSocial, setShowContainerSocial] = useState(false);
    const [showVideo, setShowVideo] = useState(true); // Default to show Video Making
    const [showDesign, setShowDesign] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);

    // Handler for Package Design
    const handleLinkClick = () => {
        setActiveOption("Package Design");
        setShowContent(true);
        setShowContent1(false);
        setShowContainerSocial(false);
        setShowVideo(false);
        setShowDesign(false);
    };

    // Handler for Logo Works
    const handleLinkClick1 = () => {
        setActiveOption("Logo Works");
        setShowContent(false);
        setShowContent1(true);
        setShowContainerSocial(false);
        setShowVideo(false);
        setShowDesign(false);
    };

    // Handler for Social Media Posters
    const handleSocialPostersClick = () => {
        setActiveOption("Social Media Posters");
        setShowContent(false);
        setShowContent1(false);
        setShowContainerSocial(true);
        setShowVideo(false);
        setShowDesign(false);
    };

    // Handler for Video Making
    const handleVideoClick = () => {
        setActiveOption("Video Making");
        setShowVideo(true);
        setShowContent(false);
        setShowContent1(false);
        setShowContainerSocial(false);
        setShowDesign(false);
    };

    // Handler for UI/UX Design
    const handleDesignClick = () => {
        setActiveOption("UI/UX Design");
        setShowDesign(true);
        setShowContent(false);
        setShowContent1(false);
        setShowVideo(false);
        setShowContainerSocial(false);
    };

    const defaultData = {
        title: 'Design Portfolio',
        subtitle: 'We are a top leading and premier <br /> creative digital marketing company in Madurai',
    };

    return (
        <div>
            <Helmet>
                <title>Social Media Marketing | Phoenix Marketing</title>
                <meta name="description" content="We know that graphic design services help to create your brand identity, credibility and boost brand visibility. Our expert designers specialize in creating designs as per client requirements." />
                <meta name="keywords" content="Logo Design Company in Madurai, Graphic Design Companies in Madurai, Graphics Designing Madurai, Best graphic design services in Madurai, Best Web Design Company in Madurai, Graphic Designing Services in Madurai, Best Graphic Design Agency in Madurai" />
                <link rel="canonical" href="https://phoenixmarketing.in/graphic-design/" />
            </Helmet>
            <Navbar />
            <div className='social-header-bg'>
                <div className='row text-contents'>
                    <div className='col-lg-6 col-md-12 col-sm-12 offset-lg-6 offset-md-6 text-content'>
                        <h3>Design Portfolio</h3>
                        <div className='line'></div>
                        <p>Explore Our Portfolio Projects. Get about
                            glimpse into our world, Design is our
                            passion. Projects are an Our Art. We
                            Create meaningful and diverse projects
                            for all levels of ambition.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row box-content">
                <div className='col-lg-1'></div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link onClick={handleVideoClick}>
                        <div className={`headings ${activeOption === "Video Making" ? 'active' : ''}`}>
                            <p>Video Making</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link id="facebook" onClick={handleSocialPostersClick}>
                        <div className={`headings ${activeOption === "Social Media Posters" ? 'active' : ''}`}>
                            <p>Social Media Posters</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link id="Instagram" onClick={handleLinkClick}>
                        <div className={`headings ${activeOption === "Package Design" ? 'active' : ''}`}>
                            <p>Package Design</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12" id="youtube">
                    <Link onClick={handleLinkClick1}>
                        <div className={`headings ${activeOption === "Logo Works" ? 'active' : ''}`}>
                            <p>Logo Works</p>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12">
                    <Link onClick={handleDesignClick}>
                        <div className={`headings ${activeOption === "UI/UX Design" ? 'active' : ''}`}>
                            <p>UI/UX Design</p>
                        </div>
                    </Link>
                </div>
                <div className='col-lg-1'></div>
            </div>

            {showContainerSocial && (
                <Socialmediaposter />
            )}

            {showContent && (
                <Packagedesign />
            )}

            {showContent1 && (
                <Logowork />
            )}

            {showVideo && (
                <Videomaking />
            )}

            {showDesign && (
                <Designuiux />
            )}

            {/* <Footer /> */}
        </div>
    );
}

export default Socialmediamarketing;
