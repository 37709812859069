import React from 'react'
import './Error.css'
import Navbar from "./Navbar";
import Footer from "./Footer";


function Error() {
  return (
    <div>
        <div>
        <Navbar />
        </div>
        <div className='container'>
    <div class="content-center">
      <h2 class="error">404</h2>
      <p>PAGE NOT FOUND</p>
      <p>It looks like nothing was found at this location.</p>
      <a href="/">
      <button>BACK TO HOME</button>
      </a>
    </div>
    </div>
    <Footer />
  </div>
  )
}


export default Error
