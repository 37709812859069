
import './Style1.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from './Componentsold/Navbar';
import Home from "./Componentsold/Home";
import About from "./Componentsold/About";
import Services from "./Componentsold/Services";
import Portfolio from "./Componentsold/Portfolio";
import Ourservice from './Componentsold/Ourservice';
import Testimonials from "./Componentsold/Testimonials";
import Contact from "./Componentsold/Contact";
import Footer from "./Componentsold/Footer";
import About1 from "./Componentsold/About1";
import Portfolio1 from './Componentsold/Portfolio1';
import Testimonials1 from './Componentsold/Testimonials1';
import Services1 from './Componentsold/Services1';
import Contact1 from './Componentsold/Contact1';
import Watsapplink from './Componentsold/Watsapplink';
import Digital from './Componentsold/Digital';
import Youtube from './Componentsold/Youtube';
import Facebookpage from './Componentsold/Facebookpage';
import Instagram1 from './Componentsold/Instagram1';
import Google from './Componentsold/Google';
import Socialmediamarketing from './Componentsold/Socialmediamarketing';
import Paidpromotionads from './Componentsold/Paidpromotionads';
import Trial from './Componentsold/Trial';
import Searchengine from './Componentsold/Searchengine';
import Webdevelopement from './Componentsold/Webdevelopement';
import Scrolltop from './Componentsold/Scrolltop';
import Error from './Componentsold/Error';

function App() {
  return (
    <div>
      <Watsapplink />
      <Router>
        <Scrolltop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about1" element={<About1 />} />
          <Route path='/testimon1' element={<Testimonials1 />} />
          <Route path='/web-development-service' element={<Services1 />} />
          <Route path="/portfolio" element={<Portfolio1 />} />
          <Route path="/contact" element={<Contact1 />} />
          <Route path="/digital-marekting-services" element={<Digital />} />
          <Route path="/youtubebusiness" element={<Youtube />} />
          <Route path="/facebookbusiness" element={< Facebookpage />} />
          <Route path="/instagrambusiness" element={< Instagram1 />} />
          <Route path="/googlebusiness" element={< Google />} />
          <Route path="/graphic-design" element={< Socialmediamarketing />} />
          <Route path="/paidpromotionads" element={< Paidpromotionads/>} />
          <Route path="/trial" element={<Trial /> } />
          <Route path="/searchengineoptimization" element={<Searchengine /> } />
          <Route path="*" element={<Error /> } />
          {/* <Route path="/webdevelope" element={<Webdevelopement /> } /> */}
        </Routes>
      </Router>

    </div>
  );
}

export default App;
